import './App.css';
import KL from './images/Keny.JPG';
import ComputerImg from './images/computer.jpg';
import CodingImg from './images/computer2.jpg';
import SFDCPDF from './files/SFDC.pdf';
import PSDImg from './images/psd.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import React, {useState, useEffect} from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { aboutMe } from './data.js';
import { aboutMeSolution } from './data.js';
import { leftSkills } from './data.js';
import { rightSkills } from './data.js';
import { jobTitle } from './data.js';
import CountryQuery from './CountryQuery.js';

function App() {
    const [userInput, setUserInput] = useState('Show me Keny`s skills and experience.');

    const aboutMeContent = aboutMe.filter(active =>
        active.active === true
    );
    const aboutMeItems = aboutMeContent.map(active =>
        <div>{active.content}<p></p></div>
    );

    const aboutMeSolutionContent = aboutMeSolution.filter(active =>
        active.active === true
    );
    const aboutMeSolutionItems = aboutMeSolutionContent.map(active=>
        <div className="About-text-header">
            {active.title}
            <div className="About-text-content">{active.content}</div>
            <p></p>
        </div>
    );

    const leftSkillsContent = leftSkills.filter(active =>
        active.active === true
    );
    const leftSkillsItems = leftSkillsContent.map(active=>
        <div className="Skill-text-header">
            {active.title}
            <div className="Skill-text-content">{active.content}</div>
            <p></p>
        </div>
    );

    const rightSkillsContent = rightSkills.filter(active =>
        active.active === true
    );
    const rightSkillsItems = rightSkillsContent.map(active=>
        <div className="Skill-text-header">
            {active.title}
            <div className="Skill-text-content">{active.content}</div>
            <p></p>
        </div>
    );

    const jobTitleContent = jobTitle.filter(active =>
        active.active === true
    );
    const jobTitleItems = jobTitleContent.map(active=>
        <li>{active.title}</li>
    );

    useEffect(() => {
        const onPageLoad = () => {
            document.getElementById('MenuClose').style.display = 'none';
            document.getElementById('MenuBody').style.display = 'none';
            document.getElementById('MenuBottom').style.display = 'none';
            document.getElementById('MenuAlt1').style.display = 'none';
            document.getElementById('MenuAlt2').style.display = 'none';
            document.getElementById('MenuAlt3').style.display = 'none';
            document.getElementById('MenuAlt4').style.display = 'none';
            document.getElementById('MenuAlt5').style.display = 'none';

            document.getElementById('MenuOpen').onclick = function(){
                MenuExpand();
            };
            document.getElementById('MenuClose').onclick = function(){
                MenuClose();
            };

            if (window.innerWidth > 1000) {
                document.getElementById('PSD_image').width = window.innerWidth * 0.5;
            } else {
                document.getElementById('PSD_image').width = window.innerWidth * 0.9;
            }

            window.addEventListener("resize", reportWindowSize);
        }

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    },[]);

    function reportWindowSize() {
        if (window.innerWidth > 1000) {
            document.getElementById('PSD_image').width = window.innerWidth * 0.5;
        } else {
            document.getElementById('PSD_image').width = window.innerWidth * 0.9;
        }
    }

    function MenuExpand() {
        document.getElementById('MenuOpen').style.display = 'none';
        document.getElementById('MenuClose').style.display = '';
        document.getElementById('MenuBody').style.display = '';
        document.getElementById('MenuBottom').style.display = '';
    }

    function MenuClose() {
        document.getElementById('MenuOpen').style.display = '';
        document.getElementById('MenuClose').style.display = 'none';
        document.getElementById('MenuBody').style.display = 'none';
        document.getElementById('MenuBottom').style.display = 'none';
        document.getElementById('MenuAlt1').style.display = 'none';
        document.getElementById('MenuAlt2').style.display = 'none';
        document.getElementById('MenuAlt3').style.display = 'none';
        document.getElementById('MenuAlt4').style.display = 'none';
        document.getElementById('MenuAlt5').style.display = 'none';
    }

    function homeOver(e) {
        e.preventDefault();
        document.getElementById('MenuAlt').style.paddingTop = '25px';
        document.getElementById('MenuAlt1').style.display = '';
    }

    function homeOut(e) {
        e.preventDefault();
        document.getElementById('MenuAlt1').style.display = 'none';
    }

    function aboutOver(e) {
        e.preventDefault();
        document.getElementById('MenuAlt').style.paddingTop = '60px';
        document.getElementById('MenuAlt2').style.display = '';
    }

    function aboutOut(e) {
        e.preventDefault();
        document.getElementById('MenuAlt2').style.display = 'none';
    }

    function expOver(e) {
        e.preventDefault();
        document.getElementById('MenuAlt').style.paddingTop = '95px';
        document.getElementById('MenuAlt3').style.display = '';
    }

    function expOut(e) {
        e.preventDefault();
        document.getElementById('MenuAlt3').style.display = 'none';
    }

    function skillOver(e) {
        e.preventDefault();
        document.getElementById('MenuAlt').style.paddingTop = '130px';
        document.getElementById('MenuAlt4').style.display = '';
    }

    function skillOut(e) {
        e.preventDefault();
        document.getElementById('MenuAlt4').style.display = 'none';
    }

    function projectOver(e) {
        e.preventDefault();
        document.getElementById('MenuAlt').style.paddingTop = '165px';
        document.getElementById('MenuAlt5').style.display = '';
    }

    function projectOut(e) {
        e.preventDefault();
        document.getElementById('MenuAlt5').style.display = 'none';
    }

    function homeScroll(e) {
        e.preventDefault();
        document.getElementById('header').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        MenuClose();
    }

    function aboutMeScroll(e) {
        e.preventDefault();
        document.getElementById('AboutDiv').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        MenuClose();
    }

    function expScroll(e) {
        e.preventDefault();
        document.getElementById('ExpDiv').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        MenuClose();
    }

    function skillScroll(e) {
        e.preventDefault();
        document.getElementById('SkillDiv').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        MenuClose();
    }

    function projectScroll(e) {
        e.preventDefault();
        document.getElementById('ProjectDiv').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        MenuClose();
    }

    const sendMessage = async (event) => {
        event.preventDefault();
        window.open('https://gemini.kenylin.com?prompt=' + document.getElementById('geminiQuery').value, '_blank', 'noreferrer');
    }

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    }

  return (
    <div className="App">
        <div className="Menu-top-div" id="MenuTop">
            <a href="#0" className="Menu-link"><FontAwesomeIcon icon={faBars} size="lg" id="MenuOpen" /></a>
            <a href="#0" className="Menu-link"><FontAwesomeIcon icon={faXmark} size="lg" id="MenuClose" /></a>
        </div>
        <div className="Menu-body-div" id="MenuBody">
            <ul className="Menu-ul">
                <li><a href="#0" className="Menu-link" onClick={homeScroll} onMouseOver={homeOver} onMouseOut={homeOut}><FontAwesomeIcon icon={faHome} size="lg" /></a></li>
                <li><a href="#0" className="Menu-link" onClick={aboutMeScroll} onMouseOver={aboutOver} onMouseOut={aboutOut}><FontAwesomeIcon icon={faUser} size="lg" /></a></li>
                <li><a href="#0" className="Menu-link" onClick={expScroll} onMouseOver={expOver} onMouseOut={expOut}><FontAwesomeIcon icon={faUnlock} size="lg" /></a></li>
                <li><a href="#0" className="Menu-link" onClick={skillScroll} onMouseOver={skillOver} onMouseOut={skillOut}><FontAwesomeIcon icon={faThumbsUp} size="lg" /></a></li>
                <li><a href="#0" className="Menu-link" onClick={projectScroll} onMouseOver={projectOver} onMouseOut={projectOut}><FontAwesomeIcon icon={faMugSaucer} size="lg" /></a></li>
            </ul>
        </div>
        <div className="Menu-alt-div" id="MenuAlt">
            <ul className="Alt-ul">
                <li className="Alt-li" id="MenuAlt1">HOME</li>
                <li className="Alt-li" id="MenuAlt2">ABOUT ME</li>
                <li className="Alt-li" id="MenuAlt3">EXPERIENCE</li>
                <li className="Alt-li" id="MenuAlt4">SKILLS</li>
                <li className="Alt-li" id="MenuAlt5">SHOWCASE</li>
            </ul>
        </div>
        <div className="Menu-bottom-div" id="MenuBottom">
        </div>
        <header className="App-header">
            <div id="header"></div>

            <div className="Input-div">
                <br />
                Ask AI about Keny's skills and experience.
                <form onSubmit={sendMessage}>
                    <input
                        id="geminiQuery"
                        className="Input_box"
                        value={userInput}
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Enter your prompt here"
                    />
                    &nbsp;
                    <button className="Input_button" type="submit">Send</button>
                </form>
            </div>

            <BrowserView>
                <table className="Header-table">
                    <tbody>
                    <tr>
                        <td width="50%" className="Header-tableTd">
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td width="30%">&nbsp;</td>
                                    <td>
                                        <div className="Header-leftDiv">
                                            <img src={KL} className="Img-KL" alt="Keny Lin"/>
                                            <br/><br/>
                                            <div className="Profile-name">
                                                KENY LIN
                                            </div>
                                            <div className="Profile-title">
                                                <ul>{jobTitleItems}</ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className="Header-tableTd">
                            <div className="Profile-header">
                                Career Goal
                            </div>
                            <br/>
                            <div className="Profile-content">
                                Throughout my career, I have been successful in streamlining IT operations, implementing
                                cost-effective technology solutions, and creating strategic plans to align IT with
                                business goals.<br/><br/>
                                My dedication to staying current with the latest industry trends and technological
                                advancements has helped me to drive innovation and continuous improvement within the
                                customers I have served.<br/>&nbsp;
                            </div>
                            <hr className="Profile-hr"/>
                            <div className="Profile-contact">
                                <table className="Profile-contact-table">
                                    <tbody>
                                    <tr>
                                        <td width="10%" className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faLocationDot}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            San Francisco Bay Area
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link"
                                               href="mailto:keny.ywl@gmail.com">keny.ywl@gmail.com</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faLinkedin}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank"
                                               href="https://www.linkedin.com/in/kenylin/"
                                               rel="noreferrer">https://www.linkedin.com/in/kenylin/</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faSalesforce}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank"
                                               href="https://www.salesforce.com/trailblazer/kenylin"
                                               rel="noreferrer">https://www.salesforce.com/trailblazer/kenylin/</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faGithub}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank" href="https://github.com/Kenytw"
                                               rel="noreferrer">https://github.com/Kenytw</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </BrowserView>
            <MobileView>
                <table className="Header-table">
                    <tbody>
                    <tr>
                        <td width="100%" className="Header-tableTd">
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td width="5%">&nbsp;</td>
                                    <td>
                                        <div className="Header-leftDiv-mobile">
                                            <img src={KL} className="Img-KL" alt="Keny Lin"/>
                                            <br/><br/>
                                            <div className="Profile-name">
                                                KENY LIN
                                            </div>
                                            <div className="Profile-title">
                                                <ul>{jobTitleItems}</ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="5%">&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="Header-tableTd">
                            <div className="Profile-header">
                                Career Goal
                            </div>
                            <br/>
                            <div className="Profile-content">
                                Throughout my career, I have been successful in streamlining IT operations, implementing
                                cost-effective technology solutions, and creating strategic plans to align IT with
                                business goals.<br/><br/>
                                My dedication to staying current with the latest industry trends and technological
                                advancements has helped me to drive innovation and continuous improvement within the
                                customers I have served.<br/>&nbsp;
                            </div>
                            <hr className="Profile-hr"/>
                            <div className="Profile-contact">
                                <table className="Profile-contact-table">
                                    <tbody>
                                    <tr>
                                        <td width="10%" className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faLocationDot}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            San Francisco Bay Area
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link"
                                               href="mailto:keny.ywl@gmail.com">keny.ywl@gmail.com</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faLinkedin}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank"
                                               href="https://www.linkedin.com/in/kenylin/"
                                               rel="noreferrer">https://www.linkedin.com/in/kenylin/</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faSalesforce}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank"
                                               href="https://www.salesforce.com/trailblazer/kenylin"
                                               rel="noreferrer">https://www.salesforce.com/trailblazer/kenylin/</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="Profile-contact-td">
                                            <FontAwesomeIcon icon={faGithub}/>
                                        </td>
                                        <td className="Profile-contact-td2">
                                            <a className="App-link" target="_blank" href="https://github.com/Kenytw"
                                               rel="noreferrer">https://github.com/Kenytw</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </MobileView>
            <div className="Down-div">
                This website is built using the <b>React.js</b> framework, with <b>JavaScript</b> and <b>CSS</b>. More
                case studies can be found at the bottom of the page.
                <br/><br/><a href="#0" className="Menu-link" onClick={projectScroll}><FontAwesomeIcon icon={faAngleDown}
                                                                                                      size="lg" bounce/></a>
            </div>
        </header>
        <div className="About-div">
            <div className="About-header" id="AboutDiv">ABOUT ME</div>
            <BrowserView>
                <table className="About-table">
                    <tbody>
                    <tr>
                        <td width="50%" className="About-img-td">
                            <img src={ComputerImg} className="Img-About" alt=""/>
                        </td>
                        <td className="About-text-td">
                            {aboutMeItems}
                        </td>
                    </tr>
                    <tr>
                        <td className="About-text-td">
                            {aboutMeSolutionItems}
                        </td>
                      <td className="About-img-td">
                          <img src={CodingImg} className="Img-About" alt="" />
                      </td>
                  </tr>
              </tbody>
            </table>
            </BrowserView>
            <MobileView>
                <table className="About-table">
              <tbody>
                  <tr>
                      <td width="50%" className="About-img-td">
                          <img src={ComputerImg} className="Img-About" alt="" />
                      </td>
                  </tr>
                  <tr>
                      <td className="About-text-td">
                          {aboutMeItems}
                      </td>
                  </tr>
                  <tr>
                      <td className="About-img-td">
                          <img src={CodingImg} className="Img-About" alt="" />
                      </td>
                  </tr>
                  <tr>
                      <td className="About-text-td">
                          {aboutMeSolutionItems}
                      </td>
                  </tr>
              </tbody>
            </table>
            </MobileView>
        </div>
        <div className="Exp-div">
            <div className="Exp-header" id="ExpDiv">EXPERIENCE</div>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Jun 2023 - Present</td>
                        <td className="Exp-name">Freelancer</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">
                            Freelancer
                        </td>
                        <td className="Exp-detail">
                             <ul>
                                 <li>Web Developer - Front-End and Full-Stack</li>
                                 <li>PSD -> Figma -> HTML</li>
                                 <li>Salesforce Administrator and Developer</li>
                                 <li>CRM Consultant - Opportunity Management, Account Management and Report/Dashboard improvement</li>
                             </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="Exp-hr" /><p></p>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Oct 2013 - May 2023</td>
                        <td className="Exp-name">ASUS North America - Fremont, CA, US</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">Director of IT and Operations</td>
                        <td className="Exp-detail">
                            <b>Oversaw the administration and monitoring of IT enterprise classified and unclassified networks, applications, servers, storage, and database.</b>
                             <ul>
                                 <li>Possessed extensive knowledge of IT policies, standards, procedures and best practices.</li>
                                 <li>Designed, implemented and ensured efficient maintenance and compliance to all disaster recovery plans.</li>
                                 <li>Reviewed daily activities, projects staffing requirements, resources.</li>
                             </ul>
                            <br /><b>Notable Achievements</b>
                            <ul>
                                <li>Official website, product landing page and eCommerce platform (Magento)</li>
                                <li>Reduced manual jobs by 80% by implementing:
                                    <ul>
                                        <li>ERP: Implemented Oracle EBS as the program leader role</li>
                                        <li>AI/ML: Identify duplicated customer information in database, verify vendors invoice</li>
                                        <li>CRM: Implemented and customized Salesforce Sales Cloud, Service Cloud and Marketing Cloud (API for backend data exchange)</li>
                                        <li>B2B data interchange: Mule Soft, EDI, API, Web Service</li>
                                        <li>BI tools: Implemented Tableau CRM (Salesforce Einstein Analytics), Tableau, Power BI, Analyzer and Automation Anywhere</li>
                                        <li>Internal use systems: HR ADP/Workday data integration, HR recruiting data integration, Accounting eBilling system, Finance OPEX/Income Statement dashboards, Internal approval systems (JIRA/BPM/DocuSign), badge control system, camera system and punch machine data integration</li>
                                        <li>3rd party vendors cooperation: Microsoft, Google, Oracle, Salesforce, Atlassian, DocuSign, Automation Anywhere, ADP, Workday, ISP, IDC and outsourcing development vendors</li>
                                    </ul>
                                </li>
                                <li>Reduced IT operations cost by 43% by implementing:
                                    <ul>
                                        <li>Reviewed ISP service for LAN between office and 3rd party ASP and Call Center</li>
                                        <li>Improved IT operations flows and reduced the process lead time by 60%</li>
                                        <li>Improved OA (Office Assets) management/flows, JIRA ticket flows and setup automatic process</li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="Exp-hr" /><p></p>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Nov 2008 - Oct 2013</td>
                        <td className="Exp-name">ASUS Tek Inc. - Taipei, Taiwan</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">Project Specialist</td>
                        <td className="Exp-detail">
                            <b>Provided leadership and drove it to completion. Ownership over all aspects of project scope, budget, and timeline.</b>
                             <ul>
                                 <li>Standardized, simplified, rationalized, and organized project reporting to improve execution.</li>
                                 <li>Provided project leadership and fostered relationships with customer stakeholders to manage expectations of project deliverables and maintain alignment of project scope with client business objectives.</li>
                                 <li>Accountable for all aspects of project budget.</li>
                             </ul>
                            <br /><b>Notable Achievements</b>
                            <ul>
                                <li>Module Lead of BPR (Business Process Re-engineering) - IT Demand Management Process</li>
                                <li>Member of BPR (Business Process Re-engineering) – OM (Order Management, CRM) Management Process</li>
                                <li>Member of new BI implementation project</li>
                                <li>Member of "Design Thinking" project</li>
                                <li>PM of CRM (Salesforce) implementation project</li>
                                <li>Salesforce Administrator for global</li>
                                <li>Contact window of BI Sales Reports between IT and users</li>
                                <li>Member of Lean Management Consultant Team and Lean Six Sigma Program Office</li>
                                <li>SharePoint Administrator, Programmer</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="Exp-hr" /><p></p>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Nov 2004 - Jun 2008</td>
                        <td className="Exp-name">Dammi Digital Technology - Taoyuan, Taiwan</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">Director of Technical</td>
                        <td className="Exp-detail">
                            <b>Responsible for writing well designed, testable, efficient code by using best software development practices.</b>
                             <ul>
                                 <li>Created eLearning platform full stack/user interface by using standard HTML5, JavaScript, ASP.Net, C#.Net and MS SQL practices.</li>
                                 <li>Gathered and refined specifications and requirements based on technical needs.</li>
                                 <li>Stayed plugged into emerging technologies/industry trends and apply them into operations and activities.</li>
                             </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="Exp-hr" /><p></p>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Mar 2004 - Nov 2004</td>
                        <td className="Exp-name">Quanta Display Inc. - Taoyuan, Taiwan</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">IT Engineer</td>
                        <td className="Exp-detail">
                            <b>Managed and maintained all computer hardware and software, including disaster recovery and well as MIS OA Team and website development.</b>
                             <ul>
                                 <li>Investigated user problems and identified their source; determined possible solutions; tested and implemented solutions.</li>
                                 <li>Trouble shooed applications to identify and corrected malfunctions and other operational difficulties.</li>
                                 <li>Developed and implemented security policies and procedures.</li>
                             </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="Exp-header" id="ExpDiv">EDUCATION</div>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">Sep 1996 - Jun 2000</td>
                        <td className="Exp-name">National Central University - Taoyuan, Taiwan</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">Bachelor of Science</td>
                        <td className="Exp-detail">
                            <b>MIS (Management Information Systems)</b>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr className="Exp-hr" /><p></p>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Exp-name" width="20%">2024</td>
                        <td className="Exp-name">Salesforce</td>
                    </tr>
                    <tr>
                        <td className="Exp-title">Salesforce Certified Administrator</td>
                        <td className="Exp-detail">
                            <b>Credential ID: 4279755</b>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p></p>&nbsp;
        </div>
        <div className="Skill-div">
            <div className="Skill-header" id="SkillDiv">SKILLS & ABILITIES</div>
            <BrowserView>
            <table className="Exp-table">
                <tbody>
                    <tr>
                        <td width="50%" className="Skill-td">{leftSkillsItems}</td>
                        <td className="Skill-td">{rightSkillsItems}</td>
                    </tr>
                </tbody>
            </table>
            </BrowserView>
            <MobileView>
                <table className="Exp-table">
                <tbody>
                    <tr>
                        <td className="Skill-td">{leftSkillsItems}</td>
                    </tr><tr>
                        <td className="Skill-td">{rightSkillsItems}</td>
                    </tr>
                </tbody>
            </table>
            </MobileView>
        </div>
        <div className="Project-div">
            <div className="Project-header" id="ProjectDiv">SHOWCASE</div>
            <table className="Project-table">
                <tbody>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-header">
                                React Query + GraphQL Request
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-content">
                                Public GraphQL API for information about countries. (API is provided by <a className="App-link" target="_blank" rel="noreferrer" href="https://trevorblades.com/">https://trevorblades.com/</a>.)
                                <br /><br />
                                <CountryQuery></CountryQuery>
                            </div>
                            <hr className="Project-hr" /><p></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-header">
                                Salesforce Administrator / Developer / Analyst / CRM Consultant
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-content">
                                <p>Salesforce Administrator, Developer, and Consultant with 12 years of experience helping organizations design and implement tailored Salesforce solutions. Successfully collaborated with ASUS North America to build and optimize Account, Lead, and Opportunity Management systems, as well as customized objects, flows, reports, and dashboards. Expertise includes Sales Cloud, CPQ, Service Cloud, Marketing Cloud, and Tableau CRM (Einstein Analytics).</p>
                                <p>Proficient in Salesforce configuration, including Workflow Automation, Process Builder, App Builder, data management, security settings, and advanced reporting/dashboard creation. Skilled in Salesforce development, such as Apex Triggers/Classes, Visualforce, Lightning Components, and API integrations with middleware (e.g., MuleSoft). Led solutions that significantly enhanced the Sales team’s efficiency, providing them with improved visibility and a perfect-fit solution for their needs.</p>
                                <FontAwesomeIcon icon={faFilePdf} /> <a href={SFDCPDF} download className="Download-link" target="_blank" rel="noreferrer">Salesforce Screening Test file</a>
                            </div>
                            <br /><hr className="Project-hr" /><p></p>
                        </td>
                    </tr>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-header">
                                3d model animated based on human pose estimation
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="Project-td">
                            <div className="Project-text-content">
                                <p>Utilize a pre-trained Computer Vision model to analyze video footage and perform human pose estimation. Based on the extracted pose data, trigger a 3D model to accurately display the corresponding human pose in real-time.</p>
                                <p><FontAwesomeIcon icon={faGithub} /> Repo: <a href="https://github.com/Kenytw/people-skeleton-detection---beta" className="Download-link" target="_blank" rel="noreferrer">https://github.com/Kenytw/people-skeleton-detection---beta</a></p>
                                <li>
                                    The front-end is developed using <a rel='noreferrer' className="Download-link" target='_blank' href='https://react.dev/'>ReactJS</a>.
                                </li>
                                <li>
                                    The 3D rendering on the front-end is powered by <a rel='noreferrer' className="Download-link" target='_blank' href='https://threejs.org/'>Three.js</a>.
                                </li>
                                <li>
                                    The back-end is implemented with <a rel='noreferrer' className="Download-link" target='_blank' href='https://flask.palletsprojects.com/en/2.3.x/'>Python Flask</a>.
                                </li>
                                <li>
                                    Object detection utilizes the <a rel='noreferrer' className="Download-link" target='_blank' href='https://docs.ultralytics.com/'>Ultralytics YOLOv8 model</a>.
                                </li>
                                <li>
                                    Pose and landmark detection are handled by the <a rel='noreferrer' className="Download-link" target='_blank' href='https://developers.google.com/mediapipe'>MediaPipe API</a>.
                                </li>
                                <li>
                                    The 3D model used is the Stacy-rigged model, available on <a rel='noreferrer' className="Download-link" target='_blank' href='https://www.turbosquid.com/3d-models/3d-stacy-resolution-games-model/535459'>TurboSquid</a>.
                                </li>
                                <br /><img src={PSDImg} alt="" id="PSD_image"></img>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>&nbsp;</p>
    </div>
  );
}

export default App;
